.css-15wpjcl .css-zc5egk {
  order: 1;
}
.css-15wpjcl .css-kmfjdt {
  margin-right: 0px;
  order: 2;
}
.css-15wpjcl .css-1hhy958 {
  order: 3;
  margin-left: 0px;
}

.css-rlsjwc {
  font-size: 15px !important;
}

.img_agents {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  height: 50px;
  width: 50px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: 50%;
  margin-left: -10px;
}

.css-y8bnhq > * {
  width: 30px !important;
  height: 30px !important;
}

.Twilio-EntryPoint {
  bottom: 50px !important;
  right: 50px !important;
  height: 47px !important;
}

.Twilio-MainContainer {
  bottom: 105px !important;
}

.Twilio-MainHeader {
  background-color: transparent !important;
  height: auto;
  padding-top: 5px;
  height: 60px !important;
}

.Twilio-MainHeader .agent_avatar {
  z-index: 3;
  margin-left: 0px !important;
  width: 100%;
  position: relative;
  padding-left: 20px;
}
.Twilio-MainHeader .agent_avatar .img_agents {
  border: 1px solid #cbc3c3;
  padding: 0px;
  background-color: #fff;
  border: 1pxsolid #cbc3c3;
  padding: 0;
  background-color: #fff;
  object-fit: cover;
}

.agent_avatar:after {
  content: "";
  height: 10px;
  width: 100%;
  background-color: #f7931d;
  position: absolute;
  bottom: 17px;
  right: 0;
  left: 0;
  z-index: -1;
}

.Twilio-online,
.close-icon-info-box:hover {
  cursor: pointer;
}
/* CSS */
.Twilio-online {
  display: -webkit-flex;
  display: flex;
  background: #fff;
  padding: 11px 8px 7px;
  border-radius: 16px;
  box-shadow: 0 0 6px #ccc;
  border: 1px solid #e3e3e3;
  font-family: "Open Sans";
}
.Twilio-online .Twilio-pic {
  position: relative;
}
.Twilio-online .Twilio-pic img {
  width: auto;
  height: 52px;
  border-radius: 50%;
  border: 1px solid #cbc3c3;
}
.Twilio-online .Twilio-pic:after {
  position: absolute;
  content: "";
  background: #21b150;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  bottom: 5px;
  right: 3px;
}
.Twilio-online .Twilio-cont {
  padding-left: 6px;
  position: relative;
  font-family: "Open Sans";
  font-size: 13px;
}
.Twilio-online .Twilio-cont h4 {
  color: #36ae62;
  font-size: 15px;
  margin: 0;
  text-transform: none !important;
  font-weight: 600;
  font-family: "Open Sans" !important;
  letter-spacing: 0px;
}
.Twilio-online .Twilio-cont p {
  color: #4e4b5c;
  padding-bottom: 0;
  font-size: 10px;
  font-weight: bold;
}
.Twilio-online:after {
  position: absolute;
  content: "";
  background: #fff;
  height: 14px;
  width: 14px;
  bottom: -3px;
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 5px 2px 3px #ededed;
}

.Twilio-wrapper {
  position: fixed;
  bottom: 112px;
  right: 50px;
  border-radius: 16px;
  width: 320px;
  z-index: 999;
  padding: 7px 0 0;
}
.Twilio-wrapper .cross {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  display: table;
  margin-left: auto;
  margin-bottom: 5px;
  margin-right: 9px;
}

.Twilio-wrapper svg {
  display: table;
  margin: 0 6px 8px auto;
  font-size: 20px;
  color: #c5bdbd;
  cursor: pointer;
}

.Twilio-ChatItem-Avatar {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
button.Twilio.Twilio-EntryPoint {
  width: 50px !important;
  height: 50px !important;
  color:white !important;
}

.Twilio-online:hover {
  cursor: pointer;
}
.Twilio.Twilio-MainContainer ~ button.Twilio.Twilio-EntryPoint {
  background-color: rgba(0, 0, 0, 0.2);
}
button.Twilio.Twilio-EntryPoint:hover{
  background-color: #e5e5e5;
}

.Twilio.Twilio-MainContainer ~ button.Twilio.Twilio-EntryPoint {
  background-image: url('/assets/images/close-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #e5e5e5;
}
.Twilio.Twilio-MainContainer ~ button.Twilio.Twilio-EntryPoint svg {
  display: none;
}

#root textarea{
  border: 0px !important;
}

#root button{
  border-radius: 100px !important;
  padding: 0px !important;
}

.Twilio-AttachFileButton{
  background-color: #fff !important;
  color: #000 !important;
}